h1, h2, h3, h4, h5, h6 {
  color: #2A2A2A;
}

html,
/* body {
  
  margin: 0;
  font-family: "linotype-didot-headline", "open sans", "serif";
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: '#2A2A2A'
} */
body {
  
  margin: 0;
  font-family: "Nunito Sans", "serif";
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: '#2A2A2A';
  line-height: 1.6rem;
}


.App {
  text-align: left;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.background {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  z-index: 0;
}

.nav-button {
  color: #E7EDEB;
  cursor: pointer;
  z-index: 9999;
  margin-right: 20px;
}

.nav-button:last-child {
  margin-right: 0px;
}

.nav-button:hover {
  color: #BBCFC9
}

/* page fade */
.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
